import { template as template_034276baf72b4ae39b1631f170cf6c15 } from "@ember/template-compiler";
const FKLabel = template_034276baf72b4ae39b1631f170cf6c15(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
