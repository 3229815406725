import { template as template_d006a408b0564f4eb44b37400aa4db57 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d006a408b0564f4eb44b37400aa4db57(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
